<template>
  <div class="main-container">
    <div class="main-header">
      <div class="logo-box">
        <img src="/images/logo.png" alt="">
      </div>
      <div class="name-box">韦氏儿童智力量表第四版中文版计分系统</div>
      <topMenu :num="1"></topMenu>
    </div>
    <div class="main-body">
      <div class="left">
        <div class="avt-bg">
          <img src="/images/avt-icon.png" alt="">
        </div>
        <div class="zs-box">
          <div class="zs-box-left">
            <span>主试编号</span>
            <span>{{ certificate_no }}</span>
          </div>
          <div class="zs-box-right">
            <span>主试姓名</span>
            <span>{{ name }}</span>
          </div>
        </div>
        <div class="num-container">
          <div class="title"><span class="line"></span>可用记分服务数：</div>
          <div class="flex">
            <div class="flex flex-1 fs">
              <a-progress type="circle" :percent="100" :width="100" :stroke-color="{
        '0%': '#ce7344',
        '100%': '#a5336f',
      }">
                <template #format="percent">
                  <div style="display: flex;flex-direction: column;align-items: center">
                    <div style="font-size: 12px; color: #86898B">完整版</div>
                    <div style="font-size: 12px;color: #86898B;margin-top: 5px"><span
                        style="color: #832C75;font-weight: bold;">{{ examinerNum.wisc_youth_complete_num == 0 ? 0 : examinerNum.wisc_youth_complete_num}}</span>份
                    </div>
                  </div>
                </template>
              </a-progress>
            </div>
            <div class="flex flex-1 fe">
              <a-progress type="circle" :percent="100" :width="100" :stroke-color="{
        '0%': '#ce7344',
        '100%': '#a5336f',
      }">
                <template #format="percent">
                  <div style="display: flex;flex-direction: column;align-items: center">
                    <div style="font-size: 12px; color: #86898B">简版</div>
                    <div style="font-size: 12px;color: #86898B;margin-top: 5px"><span
                        style="color: #832C75;font-weight: bold;">{{ examinerNum.wisc_youth_simple_num == 0 ? 0 :  examinerNum.wisc_youth_simple_num}}</span>份
                    </div>
                  </div>
                </template>
              </a-progress>
            </div>
          </div>
        </div>
        <div class="num-container" style="margin-top: 20px;border-bottom: none">
          <div class="title"><span class="line"></span>已用记分服务数：</div>
          <div class="flex">
            <div class="flex flex-1 fs">
              <a-progress type="circle" :percent="examinerNum.wisc_youth_complete_num / usedRecord.used_wisc_youth_complete_num" :width="100" :stroke-color="{
        '0%': '#ce7344',
        '100%': '#a5336f',
      }">
                <template #format="percent">
                  <div style="display: flex;flex-direction: column;align-items: center">
                    <div style="font-size: 12px; color: #86898B">完整版</div>
                    <div style="font-size: 12px;color: #86898B;margin-top: 5px"><span style="color: #832C75;font-weight: bold;">{{ usedRecord.used_wisc_youth_complete_num == 0 ? 0 : usedRecord.used_wisc_youth_complete_num }}</span>份</div>
                  </div>
                </template>
              </a-progress>
            </div>
            <div class="flex flex-1 fe">
              <a-progress type="circle" :percent="examinerNum.wisc_youth_simple_num / usedRecord.used_wisc_youth_complete_num" :width="100" :stroke-color="{
        '0%': '#ce7344',
        '100%': '#a5336f',
      }">
                <template #format="percent">
                  <div style="display: flex;flex-direction: column;align-items: center">
                    <div style="font-size: 12px; color: #86898B">简版</div>
                    <div style="font-size: 12px;color: #86898B;margin-top: 5px"><span style="color: #832C75;font-weight: bold;">{{ usedRecord.used_wisc_youth_simple_num == 0 ? 0 : usedRecord.used_wisc_youth_simple_num }}</span>份</div>
                  </div>
                </template>
              </a-progress>
            </div>
          </div>
        </div>
        <div class="result-box">
          <span>报告结果： <span class="active">{{ totalNum }}</span> 份</span>
        </div>
        <div class="login-date">上次登录：{{ login_time }}</div>
      </div>
      <div class="right">
        <div class="content" style="margin: 0">
          <div class="header">
            <div class="title-box"><img src="/images/input-header-icon.png" alt=""><span
                class="name">最新提交记分数据列表</span><span class="name"
                                                    style="font-size: 13px;margin-left: 20px">{{ currentTime }}</span>
            </div>
          </div>
          <div class="body">
            <div class="search-box">
              <div class="search-form">
                <a-form-model layout="inline">
                  <a-form-model-item label="诊疗卡号">
                    <a-input allowClear placeholder="" class="cu-input input-cuw" v-model="page.archives_num"/>
                  </a-form-model-item>
                  <a-form-model-item label="儿童姓名">
                    <a-input allowClear placeholder="" class="cu-input input-cuw" v-model="page.name"/>
                  </a-form-model-item>
                  <a-form-model-item label="报告编号">
                    <a-input allowClear placeholder="" class="cu-input input-cuw" v-model="page.scoring_num"/>
                  </a-form-model-item>

                  <a-form-model-item label="测试类型">
                    <a-select allowClear placeholder="请选择" v-model="page.edition" style="width: 150px">
                      <a-select-option value="4">完整版</a-select-option>
                      <a-select-option value="5">简版</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item>
                    <a-range-picker style="width:220px" v-model="rang" allowClear @change="changeRangeDate" format="YYYY-MM-DD"/>
                  </a-form-model-item>
                  <a-form-model-item>
                    <span class="search-btn" @click="getList()"><img src="/images/search.png" alt=""></span>
                  </a-form-model-item>

                  <a-form-model-item>
                    <span class="search-btn" @click="reset()">重置</span>
                  </a-form-model-item>
                </a-form-model>
              </div>
              <div class="search-add" @click="goBaseData"><span class="add-btn"><img src="/images/add.png" alt=""><span>添加数据</span></span>
              </div>
            </div>
            <div class="list-box">
              <a-table :columns="columns" :data-source="list" :pagination="false">
                <template slot="edition" slot-scope="text, record">
                  <span v-if="text == 4">完整版</span>
                  <span v-if="text == 5">简版</span>
                </template>
                <template slot="action" slot-scope="text, record" style="display: flex;align-items: center">
                  <a class="op-box" target="_blank" :href="'http://' + record.down_pdf_url" download="pdf"><img src="/static/download.png" style="width: 20px;height: 20px"
                                            alt=""><span>下载</span></a>
                  <span class="de-line"></span>
                  <span class="op-box" @click.stop="goEdit(record, 'index')"><img src="/static/edit.png"
                                                                    style="width: 14px;height: 14px"
                                                                    alt=""><span>编辑</span></span>
                  <span class="de-line"></span>
                  <span class="op-box" @click.stop="openDetail(record)"><img src="/static/eye.png"
                                                                                  style="width: 16px;height: 16px"
                                                                                  alt=""><span>详情</span></span>
                </template>
              </a-table>
            </div>
            <div class="content-page cu-page" style="display: flex;align-items: center;justify-content: flex-end;margin-top: 10px">
              <a-pagination :hideOnSinglePage="true" v-model="page.start" :page-size.sync="page.pageSize"  show-quick-jumper
                            :total="page.totalRow" show-less-items @change="changePage"/>
            </div> 
          </div>
        </div>
      </div>
    </div>
    <detail :item="item" :detailVisible="detailVisible" @detailCancel="detailCancel"/>
    <footer-bottom></footer-bottom>
    <bind-list @changeBindList="changeBindList" :id="id" :type="'select'" name="" gender="" born_time="" edition=""
               scoring_type="" :is-visible="bindListVisible"></bind-list>

  </div>
</template>

<script>
import * as Api from './api';
import {GetInfo} from '../../commonApi';
import {message} from "ant-design-vue";
import moment from 'moment';

export default {
  data() {
    return {
      base_url: process.env.VUE_APP_API,
      login_code: window.localStorage.getItem('login_code'),
      labelCol: {span: 8, style: {color: '#7E8589', fontWeight: '500'}},
      wrapperCol: {span: 14},
      columns: [
        {
          title: '诊疗卡号',
          dataIndex: 'archives_num',
          key: 'archives_num',
          scopedSlots: {customRender: 'archives_num'},
        },
        {
          title: '报告编号',
          dataIndex: 'scoring_num',
          key: 'scoring_num',
          scopedSlots: {customRender: 'scoring_num'},
        },
        {
          title: '儿童姓名',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: {customRender: 'name'},
        },
        {
          title: '测试类型',
          dataIndex: 'edition',
          key: 'edition',
          scopedSlots: {customRender: 'edition'},
        },
        {
          title: '测试时间',
          dataIndex: 'test_date',
          key: 'test_date',
          scopedSlots: {customRender: 'test_date'},
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          key: 'create_time',
          scopedSlots: {customRender: 'create_time'},
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          scopedSlots: {customRender: 'action'},
          width: 200
        },
      ],
      rang:null,
      page: {
        start: 1,
        limit: 10,
        totalRow: 0,
        pageSize: 0,
        name: '',
        archives_num: '',
        scoring_num: '',
        edition: undefined,
        isToday: false,
        startTime: '',
        endTime: ''
      },
      list: [],
      name: '',
      certificate_no: '',
      examinerNum: {
        "wisc_youth_simple_num": 0,
        "wisc_youth_complete_num": 0
      },
      usedRecord: {
        "used_wisc_youth_complete_num": 0,
        "used_wisc_youth_simple_num": 0
      },
      login_time: '',
      totalNum: '',
      currentTime: '',
      bindListVisible: false,
      id: '',
      detailVisible: false,
      item: {}
    };
  },
  mounted() {
    this.getList();
    this.getInfo();

    let start = this.$route.params.start;
    if (start>=0){
        this.page.start = start
    }
    let d = moment(new Date(window.localStorage.getItem('login_time'))).format('YYYY-MM-DD HH:mm:ss')
    let c = moment(Date.now()).format('ll')
    this.login_time = d;
    this.currentTime = c + '报表查询';
  },
  methods: {
    goBaseData() {
      this.$router.push({path: '/base-info'});
    },
    goSelectAge() {
      this.$router.push({path: '/select-age'})
    },
    async getList() {
      const res = await Api.List(this.page);
      if (res && res.code == 0) {
        this.list = res.page.list;
        this.page.totalRow = res.page.totalRow;
        this.page.pageSize = res.page.pageSize;
      }
    },
    reset(){
        this.page.archives_num=''
        this.page.name='' 
        this.page.scoring_num=''
        this.rang=null
        this.page.edition=undefined
        this.page.startTime = '';
        this.page.endTime = '';
        this.getList();
    },
    async getInfo() {
      const res = await GetInfo({});
      if (res && res.code == 0) {
        this.name = res.chiefExaminer.name;
        this.certificate_no = res.chiefExaminer.certificate_no;
        this.examinerNum = res.examinerNum;
        this.usedRecord = res.usedRecord;
        this.totalNum = res.usedRecord.totalYouth;
      }
    },
    changePage(e) {
      this.page.start = e;
      this.getList();
    },
    openBindList(id) {
      this.bindListVisible = true;
      this.id = id;
    },
    changeBindList(e) {
      if (e.type == 'cancel') {
        this.bindListVisible = false;
      } else if (e.type == 'select') {
        this.bind(e.data.id);
      }
    },
    async bind(id) {
      const res = await Api.Binding({binding_id: id, id: this.id});
      if (res && res.code == 0) {
        message.success({content: res.message});
        this.bindListVisible = false;
        this.getList();
      }
    },
    goEdit(record, srouce) {
      if (record.edition == 4) {
        this.$router.push({path: '/complete', query: {id: record.id, source: srouce,start:this.page.start}})
      } else if (record.edition == 5) {
        this.$router.push({path: '/simple', query: {id: record.id, source: srouce,start:this.page.start}})
      }
    },
    openDetail(item) {
      this.item = item;
      this.detailVisible = true;
    },
    detailCancel() {
      this.detailVisible = false;
    },
    changeRangeDate(e, s) {
      if (s) {
        this.page.startTime = s[0];
        this.page.endTime = s[1];
      } else {
        this.page.startTime = '';
        this.page.endTime = '';
      }
    }
  }
};
</script>
<style>
.ant-table-thead > tr > th {
  color: #832C75 !important;
  border-top: 2px solid #832C75;
  border-bottom: none;
  background-color: #F2F3F5;
}

.ant-progress-success-bg, .ant-progress-bg {
  background-color: #832C75;
}

.op-item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 13px;
  cursor: pointer;
}

a.op-item, a.op-item:link {
  color: #666666;
}
</style>
<style scoped>
.avt-bg {
  width: 100%;
  height: 100px;
  background-image: url("/images/index-left-avt.png");
  display: flex;
  justify-content: center;
  background-size: contain;
  position: relative;
}

.avt-bg img {
  width: 120px;
  position: absolute;
  top: 10px;
}

.zs-box {
  display: flex;
  border-bottom: 1px solid #F2F3F5;
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.zs-box .zs-box-left, .zs-box .zs-box-right {
  flex: 1;
}

.zs-box .zs-box-right {
  border-left: 1px solid #F2F3F5
}

.zs-box .zs-box-left span, .zs-box .zs-box-right span {
  font-size: 14px;
  font-weight: 500;
  color: #4A4E51;
  display: block;
  text-align: center;
  line-height: 24px;
}

.main-body {
  background-image: url("/images/maintain-bg.png");
  align-items: flex-start;
  justify-content: flex-start;
}

.left {
  width: 260px;
  background: #FFFFFF;
  box-shadow: 0px 8px 25px 0px rgba(242, 243, 245, 0.28);
  border-radius: 12px;
  padding: 20px;
  height: 100%;
  overflow-y: scroll;
}

.right {
  flex: 1;
  margin-left: 20px;
  height: 100%;
  overflow-y: scroll;
}

.search-box {
  display: flex;
}

.search-box .search-form {
  flex: 1;
}

.search-btn {
  background-color: #D08846 !important;
  padding: 0px 15px !important;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
  color: white;
}

.search-btn img {
  width: 14px;
}

.search-add {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 110px;
  cursor: pointer;
}

.add-btn {
  background: #832C75;
  border-radius: 5px;
  padding: 10px 15px !important;
  display: inline-block;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.add-btn img {
  width: 14px;
  margin-right: 5px;
}

.input-cuw {
  width: 110px;
}

.list-box {
  margin-top: 40px;
}

.title {
  font-size: 14px;
  font-weight: 500;
  color: #4A4E51;
  margin-bottom: 15px;
}

.title .line {
  height: 10px;
  border-left: 3px solid #832C75;
  margin-right: 10px;
}

.num-container {
  border-bottom: 1px solid #F2F3F5;
  padding-bottom: 10px;
}

.num-box {
  margin-bottom: 10px;
  padding-left: 12px;
}

.num-box .name {
  font-size: 12px;
  font-weight: 500;
  color: #86898B;
}

.progress-box {
  display: flex;
}

.progress-box .progress {
  flex: 1;
}

.progress-box .text {
  width: 60px;
  display: flex;
  justify-content: flex-end;
}

.progress-box .text {
  font-size: 12px;
  font-weight: 500;
  color: #86898B;
}

.progress-box .text span.active {
  color: #832C75;
}

.result-box {
  background-color: #F2F3F5;
  font-size: 14px;
  font-weight: 600;
  color: #4A4E51;
  text-align: center;
  padding: 10px 0;
  border-radius: 8px;
}

.result-box .active {
  color: #832C75;
}

.login-date {
  font-size: 13px;
  font-weight: 500;
  color: #7E8589;
  margin-top: 15px;
}

.de-line {
  border-right: 1px solid #ddd;
  height: 4px;
  margin: 0 10px;
}

.op-box {
  cursor: pointer;
  color: #666666;
  font-size: 12px;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.fs {
  justify-content: flex-start;
}
.fe {
  justify-content: flex-end;
}
</style>
